<template>
  <div class="warp">
    <div class="add-goods" @click="toBuhuo">补货</div>
    <div class="tips" @click="noth">非硬件故障请不要点击处理</div>
    <div class="add-goods" @click="noth">调试</div>
    <div class="tools-box">
      <div class="tool" @click="noth">换锁</div>
      <div class="tool" @click="noth">换卡</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      vmcId: this.$route.query.vmcId || '',
      address: ''
    }
  },
  methods: {
    toBuhuo() {
      if (this.vmcId == '') {
        Toast.fail('请点击右上角扫描机器二维码')
        return
      }
      this.$router.push({
        name: 'site_detail',
        query: {
          vmcId: this.vmcId
        }
      })
    },
    noth() {
      Toast('后续开发')
    }
  }
}
</script>

<style scoped lang="less">
.warp {
  padding: 15px 10px;
  .addr {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }

  .add-goods {
    width: 100%;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 8px;
    font-size: 40px;
    border: 1px solid #aaa;
    color: #333;
  }

  .tips {
    font-size: 16px;
    color: #F73500;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .tools-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .tool {
      width: calc(50% - 10px);
      height: 120px;
      line-height: 120px;
      text-align: center;
      border-radius: 8px;
      font-size: 40px;
      border: 1px solid #aaa;
    }
  }
}
</style>
